import React from 'react';
import Button from '@material-ui/core/Button';
import InputField from '../../fields/Input/InputField';
import { withStyles } from '@material-ui/core';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import * as Yup from 'yup';
import validationErrors from '../../helpers/validationErrors';
import Typography from '@material-ui/core/Typography';
import { changeSellerPassword } from '../../../redux/modules/sellers/sellersActions';
import { getRouteParamFromUrl } from '../../helpers/getParamsFromUrl';
import { showNotification } from '../../helpers/notificationHelper';
import notificationTypes from '../../helpers/notificationTypes';
import Spinner from '../../common/ProgressIndicators/Spinner';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  button: {
    fontSize: '18px',
    padding: '4px 16px',
    width: '60%',
    backgroundColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    color: theme.palette.white,
  },
  title: {
    color: theme.palette.primary.dark,
  },
  childElement: {
    width: '100%',
    marginLeft: '50px',
    marginTop: '5px',
    marginBottom: '5px',
  },
  flexContainer: {
    minWidth: '650px',
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  input: {
    border: 'none',
    width: '600px',
    minWidth: '350px',
    fontSize: '16px',
    fontWeight: 400,
    resize: 'none',
    margin: '2% 5% 1% 5%',
    fontFamily: theme.typography.fontFamily,
  },
  formField: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '97%',
  },
});

const ChangeSellerAccountPasswordForm = (props) => {
  const { classes } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const userId = getRouteParamFromUrl(match, 'id');
  const isLoading = useSelector((state) => state.sellers.isLoading);
  const { t } = useTranslation();

  return isLoading ? (
    <Spinner />
  ) : (
    <Formik
      onSubmit={(values, actions) => {
        return dispatch(changeSellerPassword(userId, values.newPassword))
          .then(() => {
            history.push('/sellers');
          })
          .catch((err) => {
            showNotification(notificationTypes.error, err, t('Changes were not saved'));
          });
      }}
      validationSchema={Yup.object({
        newPassword: Yup.string()
          .min(7, validationErrors.minPasswordLength)
          .required(validationErrors.fieldCantBeEmpty),
        confirmNewPassword: Yup.string()
          .min(7, validationErrors.minPasswordLength)
          .oneOf([Yup.ref('newPassword'), null], t('Passwords must match'))
          .required(validationErrors.fieldCantBeEmpty),
      })}
      initialValues={{
        newPassword: '',
        confirmNewPassword: '',
      }}
    >
      {(formikProps) => (
        <form onSubmit={formikProps.handleSubmit} className={classes.flexContainer}>
          <div className={classes.childElement}>
            <Typography className={classes.title} color={'primary'} variant={'h5'}>
              {t('Changing password')}
            </Typography>
          </div>
          <InputField
            color={'secondary'}
            className={classes.input}
            name="newPassword"
            type="password"
            label={t('New password')}
            variant="outlined"
          />
          <InputField
            color={'secondary'}
            className={classes.input}
            name="confirmNewPassword"
            type="password"
            label={t('New password again')}
            variant="outlined"
          />
          <div className={classes.childElement}>
            <Button variant="contained" type="submit" className={classes.button}>
              {t('Save')}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default withStyles(styles)(ChangeSellerAccountPasswordForm);
