import * as Yup from 'yup';
import validationErrors from '../../helpers/validationErrors';
import paymentGatewayTypes from '../../helpers/paymentGatewayTypes';

const buildRequiredDependingOnPaymentGateway = (paymentGateway) => ({
  is: (field) => field && field.value === paymentGateway,
  then: Yup.string().required(validationErrors.fieldCantBeEmpty),
});

const validationShape = {
  authorizationAmount: Yup.number()
    .typeError(validationErrors.fieldShouldBePositiveNumber)
    .required(validationErrors.fieldCantBeEmpty),
  currency: Yup.string().required(validationErrors.fieldCantBeEmpty),
  paymentGateway: Yup.object().required(validationErrors.fieldCantBeEmpty),
  shopId: Yup.string().when('paymentGateway', buildRequiredDependingOnPaymentGateway(paymentGatewayTypes.bePaid)),
  publicKey: Yup.string().when('paymentGateway', buildRequiredDependingOnPaymentGateway(paymentGatewayTypes.bePaid)),
  privateKey: Yup.string().when('paymentGateway', buildRequiredDependingOnPaymentGateway(paymentGatewayTypes.bePaid)),
  apiSecret: Yup.string().when(
    'paymentGateway',
    buildRequiredDependingOnPaymentGateway(paymentGatewayTypes.cloudPayments),
  ),
  publicId: Yup.string().when(
    'paymentGateway',
    buildRequiredDependingOnPaymentGateway(paymentGatewayTypes.cloudPayments),
  ),
  userName: Yup.string().when('paymentGateway', buildRequiredDependingOnPaymentGateway(paymentGatewayTypes.sberbank)),
  password: Yup.string().when('paymentGateway', buildRequiredDependingOnPaymentGateway(paymentGatewayTypes.sberbank)),
};

export { validationShape };
