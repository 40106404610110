import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import InputField from '../../fields/Input/InputField';
import { withStyles } from '@material-ui/core';
import FullScreenLoader from '../../common/ProgressIndicators/FullScreenLoader';
import { Formik } from 'formik';
import { loginUser } from '../../../redux/modules/login/loginActions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import validationErrors from '../../helpers/validationErrors';
import { useTranslation } from 'react-i18next';
import { getMessageByErrorCode } from '../../helpers/getMessageByErrorCode';

const styles = (theme) => ({
  button: {
    fontSize: '18px',
    margin: '20px 0px',
    padding: '4px 16px',
    width: '40%',
    backgroundColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    color: theme.palette.white,
  },
  title: {
    color: theme.palette.primary.dark,
  },
  childElement: {
    width: '100%',
  },
  flexContainer: {
    width: '620px',
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 'auto',
    backgroundColor: theme.palette.white,
    borderRadius: '4px',
    border: '1px solid transparent',
  },
  input: {
    border: 'none',
    width: '90%',
    fontSize: '16px',
    fontWeight: 400,
    resize: 'none',
    marginTop: '3%',
    fontFamily: theme.typography.fontFamily,
  },
  spinner: {
    marginTop: '20px',
    marginBottom: '20px',
  },
});

const LoginForm = React.memo(function LoginForm(props) {
  const { classes } = props;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const isLoading = useSelector((state) => state.login.isLoading);
  const loginErrors = useSelector((state) => state.login.loginErrors);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  return isLoading ? (
    <FullScreenLoader />
  ) : (
    <Formik
      initialValues={{
        email: email,
        password: password,
      }}
      onSubmit={(values, actions) => {
        setEmail(values.email);
        setPassword(values.password);
        return dispatch(loginUser(values.email, values.password)).then((res) => {
          localStorage.setItem('accessToken', res.payload.accessToken);
          localStorage.setItem('accessTokenExpirationDate', res.payload.accessTokenExpirationDate);
          localStorage.setItem('refreshToken', res.payload.refreshToken);
          localStorage.setItem('currentUser', JSON.stringify(res.payload.currentUser));
          history.push('/sellers');
        });
      }}
      validationSchema={Yup.object({
        password: Yup.string().required(validationErrors.fieldCantBeEmpty),
        email: Yup.string().email(t('Please enter a valid email address')).required(validationErrors.fieldCantBeEmpty),
      })}
    >
      {(formikProps) => (
        <form onSubmit={formikProps.handleSubmit}>
          <div className={classes.flexContainer}>
            <InputField
              color={'secondary'}
              className={classes.input}
              type="email"
              name="email"
              label={t('Email address')}
              variant="outlined"
            />
            <InputField
              color={'secondary'}
              className={classes.input}
              name="password"
              type="password"
              label={t('Password')}
              variant="outlined"
            />
            {loginErrors.length !== 0 &&
              loginErrors.map((err) => (
                <span key={err.code} className={classes.input} style={{ color: 'red', textAlign: 'center' }}>
                  {getMessageByErrorCode(err.code)}
                </span>
              ))}
            <Button variant="contained" type="submit" className={classes.button}>
              {t('Log in')}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
});

export default withStyles(styles)(LoginForm);
