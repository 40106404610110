import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import React from 'react';
import SellersListPage from './components/pages/SellersList/SellersListPage';
import LoginPage from './components/pages/Login/LoginPage';
import withAuthorization from './components/hocs/withAuthorization';
import AddSellerAccountPage from './components/pages/AddSellerAccount/AddSellerAccountPage';
import AddSellerPage from './components/pages/AddSeller/AddSellerPage';
import UpdateSellerPaymentDataPage from './components/pages/UpdateSellersPaymentData/UpdateSellerPaymentDataPage';
import SellerAdminsPage from './components/pages/SellerAdmins/SellerAdminsPage';
import ChangeSellerAccountPasswordPage from './components/pages/ChangeSellerAccountPassword/ChangeSellerAccountPasswordPage';
import UpdateSellerPage from './components/pages/UpdateSeller/UpdateSellerPage';
import UpdateSellerNotificationsDataPage from './components/pages/UpdateSellerNotificationsData/UpdateSellerNotificationsDataPage';
import ChangePasswordPage from './components/pages/ChangePassword/ChangePasswordPage';
import UpdateSellerContactInfoPage from './components/pages/UpdateContactInfo/UpdateSellerContactInfoPage';
import UpdateSellerBonusesConfigurationPage from './components/pages/UpdateSellerBonusesConfiguration/UpdateSellerBonusesConfigurationPage';

export default function Routes() {
  return (
    <BrowserRouter>
      <React.Fragment>
        <Switch>
          <Route exact path="/sellers" component={withAuthorization(SellersListPage)} />
          <Route exact path="/sellers/:id/admins/add" component={withAuthorization(AddSellerAccountPage)} />
          <Route exact path="/sellers/:id/paymentData" component={withAuthorization(UpdateSellerPaymentDataPage)} />
          <Route
            exact
            path="/sellers/:id/notificationsData"
            component={withAuthorization(UpdateSellerNotificationsDataPage)}
          />
          <Route exact path="/sellers/:id/contactInfo" component={withAuthorization(UpdateSellerContactInfoPage)} />
          <Route exact path="/sellers/:id/admins" component={withAuthorization(SellerAdminsPage)} />
          <Route exact path="/sellers/:id/edit" component={withAuthorization(UpdateSellerPage)} />
          <Route
            exact
            path="/sellers/:id/bonusesConfig"
            component={withAuthorization(UpdateSellerBonusesConfigurationPage)}
          />
          <Route
            exact
            path="/sellers/admins/:id/password"
            component={withAuthorization(ChangeSellerAccountPasswordPage)}
          />
          <Route exact path="/sellers/add" component={withAuthorization(AddSellerPage)} />
          <Route exact path="/account/password" component={withAuthorization(ChangePasswordPage)} />
          <Route exact path="/login" component={LoginPage} />
          <Redirect from="*" exact to="/sellers" />
        </Switch>
      </React.Fragment>
    </BrowserRouter>
  );
}
