import React from 'react';
import { createMuiTheme } from '@material-ui/core';
import axios from 'axios';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { ThemeProvider } from '@material-ui/styles';
import { Provider } from 'react-redux';
import { store } from '../redux/store';
import Routes from '../routes';

export const globalThemeObject = {
  typography: {
    fontFamily: '"Montserrat", "Roboto", "Helvetica", "Arial", sans-serif',
  },
  palette: {
    white: '#ffffff',
    whitegrey: '#f7f7f7',
    grey: {
      light: '#f3f3f3',
      main: '#a9a9a9',
      dark: '#7f7f7f',
    },
    whiteblue: '#f4f8fd',
    lightblue: '#dfedff',
    black: '#000000',
    red: '#fc5757',
    secondary: {
      main: '#384910',
      light: '#00802b',
    },
    primary: {
      light: '#ff1a1a',
      main: '#cc0000',
      dark: '#800000',
    },
  },
  border: {
    lightgrey: '1px solid rgba(224, 224, 224, 1)',
  },
};

const globalTheme = createMuiTheme(globalThemeObject);

function App() {
  let isRefreshing = false;
  let failedQueue = [];

  const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });

    failedQueue = [];
  };

  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  // Add a request interceptor
  axios.interceptors.request.use((config) => {
    config.headers['EasyLunch-Seller-Id'] = localStorage.getItem('sellerId');
    config.headers.Authorization = 'Bearer ' + localStorage.getItem('accessToken');

    return config;
  });

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      const originalRequest = error.config;

      if (error.response && error.response.status && error.response.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest.headers['Authorization'] = 'Bearer ' + token;
              return axios(originalRequest);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        const accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');
        return new Promise(function (resolve, reject) {
          axios
            .post('authSessions/refresh', {
              refreshToken,
              accessToken,
            })
            .then(({ data }) => {
              localStorage.setItem('accessToken', data.accessToken);
              localStorage.setItem('refreshToken', data.refreshToken);
              localStorage.setItem('accessTokenExpirationDate', data.accessTokenExpirationDate);
              axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.accessToken;
              originalRequest.headers['Authorization'] = 'Bearer ' + data.accessToken;
              processQueue(null, data.token);
              resolve(axios(originalRequest));
            })
            .catch((err) => {
              processQueue(err, null);
              reject(err);
            })
            .finally(() => {
              isRefreshing = false;
            });
        });
      }
      if (originalRequest.url === 'authSessions/refresh') {
        localStorage.clear();
        window.location = '/login';
        return Promise.reject(error);
      }

      return Promise.reject(error);
    },
  );

  return (
    <React.Fragment>
      <ReactNotification />
      <ThemeProvider theme={globalTheme}>
        <Provider store={store}>
          <Routes />
        </Provider>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
