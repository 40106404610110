import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import * as Yup from 'yup';
import Spinner from '../../common/ProgressIndicators/Spinner';
import { getSellerPaymentData, updateSellerPaymentData } from '../../../redux/modules/sellers/sellersActions';
import { showNotification } from '../../helpers/notificationHelper';
import notificationTypes from '../../helpers/notificationTypes';
import UpdateSellersPaymentDataForm from './UpdateSellerPaymentDataForm';
import { validationShape } from './validationShapes';
import { getRouteParamFromUrl } from '../../helpers/getParamsFromUrl';
import paymentGatewayTypes from '../../helpers/paymentGatewayTypes';
import paymentGatewayTypesForSelect from '../../helpers/paymentGatewayTypesForSelect';
import { useTranslation } from 'react-i18next';

const UpdateSellerPaymentDataFormWrapper = () => {
  const isLoading = useSelector((state) => state.sellers.isLoading);
  const paymentData = useSelector((state) => state.sellers.paymentData);
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const sellerId = getRouteParamFromUrl(match, 'id');
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getSellerPaymentData(sellerId));
  }, [dispatch, sellerId]);

  const getPaymentGateway = () => {
    if (!isNaN(paymentData.paymentGateway) && paymentData.paymentGateway !== null) {
      const paymentGateway = paymentGatewayTypesForSelect.find((x) => x.id === paymentData.paymentGateway);
      if (paymentGateway) {
        return {
          value: paymentGateway.id,
          label: paymentGateway.name,
        };
      }
    }
    return '';
  };

  let initialValues = {
    paymentGateway: getPaymentGateway(),
    authorizationAmount: paymentData.authorizationAmount || '',
    currency: paymentData.currency || '',
  };

  if (paymentData.paymentGateway === paymentGatewayTypes.bePaid) {
    initialValues.shopId = paymentData.bePaidAccountConfig.shopId;
    initialValues.publicKey = paymentData.bePaidAccountConfig.publicKey;
    initialValues.privateKey = paymentData.bePaidAccountConfig.privateKey;
    initialValues.apiSecret = '';
    initialValues.publicId = '';
    initialValues.userName = '';
    initialValues.password = '';
  } else if (paymentData.paymentGateway === paymentGatewayTypes.cloudPayments) {
    initialValues.apiSecret = paymentData.cloudPaymentsAccountConfig.apiSecret;
    initialValues.publicId = paymentData.cloudPaymentsAccountConfig.publicId;
    initialValues.shopId = '';
    initialValues.publicKey = '';
    initialValues.privateKey = '';
    initialValues.userName = '';
    initialValues.password = '';
  } else if (paymentData.paymentGateway === paymentGatewayTypes.sberbank) {
    initialValues.userName = paymentData.sberbankAccountConfig.userName;
    initialValues.password = paymentData.sberbankAccountConfig.password;
    initialValues.apiSecret = '';
    initialValues.publicId = '';
    initialValues.shopId = '';
    initialValues.publicKey = '';
    initialValues.privateKey = '';
  } else if (paymentData.paymentGateway) {
    history.push('/sellers');
    showNotification(notificationTypes.error, t('Selected payment gateway is not supported'));
  }

  return isLoading ? (
    <Spinner />
  ) : (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, actions) => {
        return dispatch(updateSellerPaymentData(values, sellerId))
          .then(() => {
            history.push('/sellers');
          })
          .catch((err) => {
            showNotification(notificationTypes.error, err, t('Changes were not saved'));
          });
      }}
      validationSchema={Yup.object().shape(validationShape)}
    >
      {(formikProps) => <UpdateSellersPaymentDataForm formikProps={formikProps} />}
    </Formik>
  );
};

export default UpdateSellerPaymentDataFormWrapper;
