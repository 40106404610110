import React, { useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Slide, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  root: {
    width: 1200,
    margin: 'auto',
  },
  headline: {
    color: theme.palette.grey.dark,
    fontWeight: 'bold',
  },
  button: {
    margin: '0 10px',
  },
  apiTokenText: {
    overflowWrap: 'anywhere',
    wordBreak: 'break-all',
    padding: '10px',
  },
  dialogActions: {
    justifyContent: 'space-between',
  },
  slide: {
    position: 'absolute',
    bottom: '15px',
    left: '40%',
    color: theme.palette.secondary.light,
  },
  dialogContent: {
    overflow: 'hidden',
  },
});

const ApiTokenModal = (props) => {
  const { isOpen, handleClose, handleCopy, classes, sellerId, apiToken } = props;
  const { t } = useTranslation();
  const [copiedBannerIsShown, setCopiedBannerIsShown] = useState(false);

  const copyClickedEventHandler = () => {
    handleCopy();

    if (!copiedBannerIsShown) {
      setCopiedBannerIsShown(true);
      setTimeout(() => setCopiedBannerIsShown(false), 2000);
    }
  };
  return (
    <Dialog open={isOpen} onClose={handleClose} className={classes.root} classes={{ paper: classes.dialogContent }}>
      <DialogTitle>
        <div className={classes.headline}>{t("{{sellerId}} seller's API token", { sellerId: sellerId })}</div>
      </DialogTitle>
      <DialogContent dividers={true}>
        <DialogContentText tabIndex={-1} classes={{ root: classes.apiTokenText }}>
          {apiToken}
        </DialogContentText>
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActions }}>
        <Button variant={'contained'} onClick={copyClickedEventHandler} data-cy="copy" className={classes.button}>
          {t('Копировать')}
        </Button>
        <Button variant={'contained'} onClick={handleClose} data-cy="deny-btn" className={classes.button}>
          {t('Закрыть')}
        </Button>
      </DialogActions>
      <Slide direction="up" in={copiedBannerIsShown} mountOnEnter unmountOnExit className={classes.slide}>
        <Typography>{t('Скопировано')}</Typography>
      </Slide>
    </Dialog>
  );
};

export default withStyles(styles)(ApiTokenModal);
