import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Spinner from '../../common/ProgressIndicators/Spinner';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSellerAdmins } from '../../../redux/modules/sellers/sellersActions';
import { getPageNumber } from '../../helpers/queryHelper';
import { useRouteMatch } from 'react-router';
import { getRouteParamFromUrl } from '../../helpers/getParamsFromUrl';
import SellerAdminsTable from './SellerAdminsTable';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '96%',
    minWidth: 700,
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: '20px',
    marginBottom: '10px',
    backgroundColor: theme.palette.white,
    boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.06)',
  },
  gridItem: {
    margin: '10px',
  },
  title: {
    color: theme.palette.primary.dark,
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: 'inset 0 -1px 0 0 rgba(0, 0, 0, 0.09)',
  },
  addButton: {
    backgroundColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    color: theme.palette.white,
  },
});

const SellerAdminsList = (props) => {
  const { classes } = props;

  const match = useRouteMatch();
  const sellerId = getRouteParamFromUrl(match, 'id');
  const dispatch = useDispatch();
  let location = useLocation();
  const activePage = getPageNumber(location);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getSellerAdmins(sellerId, activePage));
  }, [activePage, dispatch, sellerId]);

  const admins = useSelector((state) => state.sellers.sellerAdmins);
  const isLoading = useSelector((state) => state.sellers.isLoading);
  const adminsCount = useSelector((state) => state.sellers.sellerAdminsCount);

  const history = useHistory();

  const handlePageChange = (event, pageNumber) => {
    const queryString = `page=${pageNumber}`;
    history.push({
      pathname: history.location.pathname,
      search: queryString,
    });
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Grid container direction="row" spacing={0} className={classes.container}>
          <Grid item className={classes.gridItem}>
            <Typography className={classes.title} variant={'h6'}>
              {t('The list of {{sellerId}} application admins')}
            </Typography>
          </Grid>
          <Grid item className={classes.gridItem}>
            <Button
              startIcon={<AddIcon />}
              variant={'contained'}
              className={classes.addButton}
              onClick={() => history.push(`/sellers/${sellerId}/admins/add`)}
            >
              {t('Create an account')}
            </Button>
          </Grid>
        </Grid>
        {isLoading ? (
          <Spinner />
        ) : (
          <SellerAdminsTable
            admins={admins}
            activePage={activePage}
            handlePageChange={handlePageChange}
            adminsCount={adminsCount}
            history={history}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default withStyles(styles)(SellerAdminsList);
