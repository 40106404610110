import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { validationShape } from './validationShapes';
import {
  getSellerNotificationsData,
  updateSellerNotificationsData,
} from '../../../redux/modules/sellers/sellersActions';
import { showNotification } from '../../helpers/notificationHelper';
import notificationTypes from '../../helpers/notificationTypes';
import smsSenderTypes from '../../helpers/smsSenderTypes';
import smsSenderTypesForSelect from '../../helpers/smsSenderTypesForSelect';
import { getRouteParamFromUrl } from '../../helpers/getParamsFromUrl';
import Spinner from '../../common/ProgressIndicators/Spinner';
import UpdateSellerNotificationsDataForm from './UpdateSellerNotificationsDataForm';
import { useTranslation } from 'react-i18next';

const UpdateSellerNotificationsDataFormWrapper = () => {
  const isLoading = useSelector((state) => state.sellers.isLoading);
  const notificationsData = useSelector((state) => state.sellers.notificationsData);
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const sellerId = getRouteParamFromUrl(match, 'id');
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getSellerNotificationsData(sellerId));
  }, [dispatch, sellerId]);

  const getSmsSender = () => {
    if (!isNaN(notificationsData?.smsSender) && notificationsData?.smsSender !== null) {
      const smsSender = smsSenderTypesForSelect.find((x) => x.id === notificationsData.smsSender);
      if (smsSender) {
        return {
          value: smsSender.id,
          label: smsSender.name,
        };
      }
    }
    return '';
  };

  let initialValues = {
    smsSender: getSmsSender(),
    accountSid: '',
    authToken: '',
    senderPhoneNumber: '',
    apiId: '',
    senderName: '',
  };

  if (notificationsData.smsSender === smsSenderTypes.Twilio) {
    if (notificationsData.twilioAccountConfig !== null) {
      initialValues.accountSid = notificationsData.twilioAccountConfig.accountSid;
      initialValues.authToken = notificationsData.twilioAccountConfig.authToken;
      initialValues.senderPhoneNumber = notificationsData.twilioAccountConfig.senderPhoneNumber;
    }
  } else if (notificationsData.smsSender === smsSenderTypes.SmsRu) {
    if (notificationsData.SmsRuAccountConfig !== null) {
      initialValues.apiId = notificationsData.smsRuAccountConfig.apiId;
      initialValues.senderName = notificationsData.smsRuAccountConfig.senderName;
    }
  } else if (notificationsData.smsSender) {
    history.push('/sellers');
    showNotification(notificationTypes.error, t('Selected SMS provider is not supported'));
  }

  return isLoading ? (
    <Spinner />
  ) : (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, actions) => {
        return dispatch(updateSellerNotificationsData(values, sellerId))
          .then(() => {
            history.push('/sellers');
          })
          .catch((err) => {
            showNotification(notificationTypes.error, err, t('Changes were not saved'));
          });
      }}
      validationSchema={Yup.object().shape(validationShape)}
    >
      {(formikProps) => <UpdateSellerNotificationsDataForm formikProps={formikProps} />}
    </Formik>
  );
};

export default UpdateSellerNotificationsDataFormWrapper;
