import * as Yup from 'yup';
import validationErrors from '../../helpers/validationErrors';
import smsSenderTypes from '../../helpers/smsSenderTypes';

const buildRequiredDependingOnSmsSender = (smsSender) => ({
  is: (field) => field && field.value === smsSender,
  then: Yup.string().required(validationErrors.fieldCantBeEmpty),
});

const validationShape = {
  smsSender: Yup.object().required(validationErrors.fieldCantBeEmpty),
  accountSid: Yup.string().when('smsSender', buildRequiredDependingOnSmsSender(smsSenderTypes.Twilio)),
  authToken: Yup.string().when('smsSender', buildRequiredDependingOnSmsSender(smsSenderTypes.Twilio)),
  senderPhoneNumber: Yup.string().when('smsSender', buildRequiredDependingOnSmsSender(smsSenderTypes.Twilio)),
  apiId: Yup.string().when('smsSender', buildRequiredDependingOnSmsSender(smsSenderTypes.SmsRu)),
};

export { validationShape };
