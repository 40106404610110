import i18next from 'i18next';

export default {
  fieldCantBeEmpty: i18next.t('Please fill in this field'),
  fieldShouldBePositiveNumber: i18next.t('This field must be numeric and positive'),
  fieldShouldBeNonNegativeNumber: i18next.t('This field must be numeric and non-negative'),
  minPasswordLength: i18next.t('The password must be at least 7 characters long'),
  wrongEmailFormat: i18next.t('The email is incorrect'),
  wrongPhoneNumberFormat: i18next.t('The phone number is incorrect'),
  numberMustBeInRange: (min, max) => {
    if (isNaN(min) || isNaN(max)) {
      throw new TypeError("'min' and 'max' arguments must be numbers.");
    }

    return i18next.t('The number must be between {{min}} and {{max}}', {
      min: min || '',
      max: max || '',
    });
  },
};
