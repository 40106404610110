import React from 'react';
import Button from '@material-ui/core/Button';
import InputField from '../../fields/Input/InputField';
import { withStyles } from '@material-ui/core';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import validationErrors from '../../helpers/validationErrors';
import Spinner from '../../common/ProgressIndicators/Spinner';
import Typography from '@material-ui/core/Typography';
import { addSeller } from '../../../redux/modules/sellers/sellersActions';
import { showNotification } from '../../helpers/notificationHelper';
import notificationTypes from '../../helpers/notificationTypes';
import { ImageUploadField } from '../../fields/ImageUploadField';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  button: {
    fontSize: '18px',
    padding: '4px 16px',
    width: '60%',
    backgroundColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    color: theme.palette.white,
  },
  title: {
    color: theme.palette.primary.dark,
  },
  childElement: {
    width: '100%',
    marginLeft: '50px',
    marginTop: '5px',
    marginBottom: '5px',
  },
  flexContainer: {
    minWidth: '650px',
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  input: {
    border: 'none',
    width: '600px',
    minWidth: '350px',
    fontSize: '16px',
    fontWeight: 400,
    resize: 'none',
    margin: '2% 5% 1% 5%',
    fontFamily: theme.typography.fontFamily,
  },
  formField: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '97%',
  },
});

const AddSellerForm = (props) => {
  const { classes } = props;
  const isLoading = useSelector((state) => state.sellers.isLoading);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  return isLoading ? (
    <Spinner />
  ) : (
    <Formik
      initialValues={{}}
      onSubmit={(values, actions) => {
        return dispatch(addSeller(values))
          .then(() => {
            history.push('/sellers');
          })
          .catch((err) => {
            showNotification(notificationTypes.error, err, t('Changes were not saved'));
          });
      }}
      validationSchema={Yup.object({
        id: Yup.string().required(validationErrors.fieldCantBeEmpty),
        name: Yup.string().required(validationErrors.fieldCantBeEmpty),
        pricingCoefficient: Yup.number()
          .typeError(validationErrors.fieldShouldBePositiveNumber)
          .min(0, t('This field must be between {{min}} and {{max}}', { min: 0, max: 1 }))
          .max(1, t('This field must be between {{min}} and {{max}}', { min: 0, max: 1 }))
          .required(validationErrors.fieldCantBeEmpty),
        timezone: Yup.string().required(validationErrors.fieldCantBeEmpty),
      })}
    >
      {(formikProps) => (
        <form onSubmit={formikProps.handleSubmit} className={classes.flexContainer}>
          <div className={classes.childElement}>
            <Typography className={classes.title} color={'primary'} variant={'h5'}>
              {t('Adding seller')}
            </Typography>
          </div>
          <InputField
            color={'secondary'}
            className={classes.input}
            name="id"
            type="text"
            label={t('Seller ID')}
            variant="outlined"
          />
          <InputField
            color={'secondary'}
            className={classes.input}
            name="name"
            type="text"
            label={t('Seller name')}
            variant="outlined"
          />
          <InputField
            color={'secondary'}
            className={classes.input}
            type="string"
            name="pricingCoefficient"
            label={t('First order pricing coefficient')}
            variant="outlined"
          />
          <InputField
            color={'secondary'}
            className={classes.input}
            name="timezone"
            type="text"
            label={t('Timezone')}
            variant="outlined"
          />
          <ImageUploadField permittedExtensions={['.png']} name="imageUrl" variant="outlined" />
          <div className={classes.childElement}>
            <Button variant="contained" type="submit" className={classes.button}>
              {t('Add')}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default withStyles(styles)(AddSellerForm);
